import { PublicClientApplication } from "@azure/msal-browser";
import { PUBLIC_CLIENT_CONFIGURATION, environment } from "../constants";

const configuration = {
  auth: {
    clientId: PUBLIC_CLIENT_CONFIGURATION[environment].CLIENT_ID,
    authority: `https://login.microsoftonline.com/${PUBLIC_CLIENT_CONFIGURATION[environment].TENANT_ID}`,
    redirectUri: window.location.origin + "/status",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

console.log(configuration);

const auth = new PublicClientApplication(configuration);

export default auth;
