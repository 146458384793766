import React, { Suspense } from "react";
import { ErrorBoundary, FallbackView } from "react-error-boundaries";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider } from "../context/StoreContext";
import { PageLoader } from "../components/Progress";

const AppKernel = Component => {
    // eslint-disable-next-line react/display-name
    return () => (
        <ErrorBoundary FallbackComponent={FallbackView}>
            <Suspense fallback={<PageLoader />}>
                <Router>
                    <StoreProvider>
                        <Component />
                        {/*<PostForm />*/}
                    </StoreProvider>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
};

export default AppKernel;
