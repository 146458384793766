import "core-js";
import React, { lazy } from "react";
import ReactDOM from "react-dom";
import AppKernel from "./app";
import AuthProvider from "./auth/AuthProvider";

const Page = lazy(() => import("./page"));
const App = AppKernel(Page);


// TODO: Upgrade React Version
// TODO: Switch eslint parser to babel-eslint since project does not use TypeScript
// TODO: Refactor config file into ENV file
// TODO: Set up prettier
// TODO: Audit dependencies
// TODO: Upgrade react code to typescript
ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById("root")
);
