import { useContext, useEffect, createContext, useState } from "react";
import { LOGGED_IN_USER_KEY, TOKEN_KEY } from "../constants";
import auth from "./auth";

const AuthContext = createContext({});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loginError, setLoginError] = useState();

  useEffect(() => {
    auth.initialize();
  }, []);

  useEffect(() => {
    if (user) {
      const tokenValidationInterval = setInterval(() => {
        const isTokenValid = isSessionStorageTokenValid();

        if(!isTokenValid) {
          alert("You need to reauthenticate")
          clearInterval(tokenValidationInterval);
          setUser(undefined)
        }
      },  60 * 1000 * 15 );

      return () => clearInterval(tokenValidationInterval);
    }
  }, [user]);

  const signIn = async () => {
    try {
      const user = await auth.loginPopup();
      setUser(user);
      sessionStorage.setItem(TOKEN_KEY, user.idToken);
      sessionStorage.setItem(LOGGED_IN_USER_KEY, user.account.username);
    } catch (error) {
      setLoginError("Login failed. Please try again or refresh your page if you encounter any issues.");
      throw new Error(error);
    }
  };

  const signOut = async () => {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(LOGGED_IN_USER_KEY);
    setUser(undefined);
  };

  const isSessionStorageTokenValid = () => {
    const token = sessionStorage.getItem(TOKEN_KEY);

    if (!token) return false;

    try {
      const [, payloadStr] = token.split(".");
      const payload = JSON.parse(atob(payloadStr));

      const expiration = payload.exp * 1000; // convert expiration date to miliseconds

      const now = Date.now(); // Current time in miliseconds
      return expiration >= now;
    } catch {
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loginError }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
