export const TOKEN_KEY = "msal_tokenId";
export const LOGGED_IN_USER_KEY = "msal_username";

export const PUBLIC_CLIENT_CONFIGURATION = {
  STAGING: {
    TENANT_ID: "e2418976-4544-4f71-aad4-b023e2f2cc53",
    CLIENT_ID: "dbffc079-989c-4d80-8835-c72066a63184",
  },
  PRODUCTION: {
    TENANT_ID: "c38f90d0-da54-455b-b1ae-c43b6009d294",
    CLIENT_ID: "08a701df-5ee5-4085-b045-242f7c1a652d",
  },
};

export const HOST_ENVIRONMENTS = {
  "datarights.zionsbancorp.com": "PRODUCTION",
  "datarights-c.staging.zionsbank.com": "STAGING",
  default: "STAGING",
};

export const environment =
  HOST_ENVIRONMENTS[window.location.hostname] || HOST_ENVIRONMENTS["default"];
