import React, { createContext, useReducer, useEffect } from "react";
import Log from "../Log";

import { reducer, initialState } from "./reducers";

const StoreContext = createContext(initialState);

const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        Log.info({ newState: state });
    }, [state]);

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            {children}
        </StoreContext.Provider>
    );
};

export { StoreContext, StoreProvider };
